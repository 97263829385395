import React, { useEffect } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Card, Tag } from '@grafana/ui';
import { Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [keyData, setkeyData] = React.useState<MqttKey[]>([]);
  const [clockColor, setClockColor] = React.useState<MqttKeyClockColor[]>([]);
  const [firstRun, setFirstRun] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  interface MqttKey {
    key: string;
    value: string;
    lastUpdated: number;
  }

  interface MqttKeyClockColor {
    key: string;
    color: number;
  }

  useEffect(() => {
    parseKeyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTimeColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeColor();
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockColor, keyData]);

  const parseKeyData = () => {
    let topicData = data.series[0].fields.find((field) => field.name === 'Topic');
    let valueData = data.series[0].fields.find((field) => field.name === 'Value');
    if (!firstRun) {
      if (topicData !== undefined) {
        //if latest update is the topic we are monitoring
        if (topicData.values.get(topicData.values.length - 1) === options.topic) {
          if (valueData !== undefined) {
            //get the latest json object
            let valueJson = JSON.parse(valueData.values.get(valueData.values.length - 1));
            updateKeys(valueJson);
          }
        }
      }
    } else {
      //find last occurence options.topic in topicData
      let topicIndex = topicData?.values.toArray().lastIndexOf(options.topic);
      if (topicIndex !== undefined && topicIndex !== -1) {
        let valueJson = JSON.parse(valueData?.values.get(topicIndex));
        updateKeys(valueJson);
      }

      setFirstRun(false);
    }
  };

  const updateKeys = (valueJson: any) => {
    let newKeyData = [...keyData];
    for (let key in valueJson) {
      let newKey = {
        key: key,
        value: valueJson[key],
        lastUpdated: Date.now(),
      };
      let keyIndex = newKeyData.findIndex((key) => key.key === newKey.key);
      if (keyIndex === -1) {
        newKeyData.push(newKey);
      } else {
        newKeyData[keyIndex] = newKey;
      }
    }
    setkeyData(newKeyData);
  };

  const getKeyValue = (mqttKey: string) => {
    let keyIndex = keyData.findIndex((key) => key.key === mqttKey);
    if (keyIndex === -1) {
      return 'Wating for data';
    } else {
      let finalValue = keyData[keyIndex].value.toString();
      //check if finalVakue is both a number and has a decimal
      if (!isNaN(Number(finalValue)) && finalValue.indexOf('.') !== -1) {
        //if so, round to 2 decimal places
        finalValue = Number(finalValue).toFixed(2);
        finalValue = finalValue.toString();
        return finalValue;
      }

      if (mqttKey === 'operating_status') {
        if (finalValue === '0') {
          finalValue = 'Stopped';
        }
        if (finalValue === '1') {
          finalValue = 'Run Program';
        } else if (finalValue === '2') {
          finalValue = 'Hold Program';
        } else if (finalValue === '4') {
          finalValue = 'Suspend Program';
        } else if (finalValue === '8') {
          finalValue = 'Undefined';
        } else if (finalValue === '16') {
          finalValue = 'Run Manual';
        } else if (finalValue === '32') {
          finalValue = 'Hold Manual';
        } else if (finalValue === '64') {
          finalValue = 'Undefined';
        } else if (finalValue === '128') {
          finalValue = 'Undefined';
        }
      }
      return finalValue;
    }
  };

  const getKeyDisplayName = (mqttKey: string) => {
    let splitString = mqttKey.split(';');
    if (splitString.length > 1) {
      return splitString[1];
    }
    return splitString[0];
  };

  const getKeyName = (mqttKey: string) => {
    let splitString = mqttKey.split(';');
    return splitString[0];
  };

  const getLastUpdated = (mqttKey: string) => {
    let keyIndex = keyData.findIndex((key) => key.key === mqttKey); //find index of key in keyData
    if (keyIndex === -1) {
      return 'N/A';
    } else {
      let lastUpdated = keyData[keyIndex].lastUpdated;
      //convert lastUpdated to humand readable date and time
      let date = new Date(lastUpdated);
      let hours = date.getHours();
      let minutes = '0' + date.getMinutes();
      let seconds = '0' + date.getSeconds();
      let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
      return 'Last Updated: ' + date.toLocaleDateString() + ' ' + formattedTime;
    }
  };

  const setTimeColor = () => {
    //let keyIndex = keyData.findIndex((key) => key.key === mqttKey);
    let staleThreshold = 0;
    let clockColorTmp = [...clockColor];

    //this works fine for now, but we should add typecheck to editor to make sure it is a number
    if (isNaN(+options.stale)) {
      //stale is not a number
      staleThreshold = 15;
    } else {
      //stale is a number
      staleThreshold = +options.stale;
    }

    //for every key in keyData, check if it is stale
    for (let i = 0; i < keyData.length; i++) {
      let keyIndex = clockColorTmp.findIndex((key) => key.key === keyData[i].key);
      if (keyIndex === -1) {
        //key is not in clockColor
        let newClockColor = {
          key: keyData[i].key,
          color: 6,
        };
        clockColorTmp.push(newClockColor);
      } else {
        //key is in clockColor
        let timeSinceUpdate = Date.now() - keyData[i].lastUpdated;
        if (timeSinceUpdate > staleThreshold * 1000) {
          //key is stale
          clockColorTmp[keyIndex].color = 9;
        } else {
          //key is not stale
          clockColorTmp[keyIndex].color = 6;
        }
      }
    }
    setClockColor(clockColorTmp);
  };



  const getClockColor = (mqttKey: string) => {
    let keyIndex = clockColor.findIndex((key) => key.key === mqttKey);
    if (keyIndex === -1) {
      return 9;
    } else {
      return clockColor[keyIndex].color;
    }
  };


  const Cards = () => {
    let cards: JSX.Element[] = [];
    options.keys.map((key) => {
      cards.push(
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
              modifiers:[{
                name: 'offset',
                options: {
                  offset: [0, -60],
                },
              }]
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 0 }}
            placement='bottom'
            title={getLastUpdated(getKeyName(key))}
          >
            <div style={{ cursor: 'pointer', overflow: 'auto', width: '100%'}} onClick={handleTooltipOpen}>
              <Card >
                {getKeyDisplayName(key)}
                <Card.Tags>
                  <Tag name={getKeyValue(getKeyName(key))} colorIndex={getClockColor(getKeyName(key))} />
                </Card.Tags>
              </Card>
            </div>
          </Tooltip>
        </ClickAwayListener>
      );
    });

    return <div>{cards}</div>;
  };

  return (
    <div style={{overflow: 'auto', height: '100%'}}>
      <Cards />
    </div>
  );




  
};
