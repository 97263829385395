import React from 'react';
import { Input } from '@grafana/ui';
import { StandardEditorProps } from '@grafana/data';

export const KeyPicker: React.FC<StandardEditorProps<string[]>> = ({ item, value, onChange }) => {
  const addKeyToArray = (index: number, key: string) => {
    let newKeys = [...value];
    newKeys[index] = key;

    //remove empty keys
    let cleanedNewKeys = newKeys.filter((key) => key !== '');

    return cleanedNewKeys;
  };

  const mqttKeys = () => {
    let keyArray = [];
    for (let i = 0; i < value.length + 1; i++) {
      keyArray.push(
        <div>
          <Input
            value={value[i]}
            onChange={(e) => onChange(addKeyToArray(i, e.currentTarget.value))}
            placeholder="Enter a value"
          />
          <br></br>
        </div>
      );
    }
    return keyArray;
  };

  return <div>{mqttKeys()}</div>;
};
