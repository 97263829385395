import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';
import { KeyPicker } from './components/KeyPicker';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
    .addTextInput({
      path: 'stale',
      name: 'Stale Threshold, Default 15s',
      description: 'How long to wait before considering a key stale (seconds)',
      defaultValue: '15',
    })
    .addTextInput({
      path: 'topic',
      name: 'Topic',
      description: 'Topic to monitor for MQTT updates',
      defaultValue: '',
    })
    .addCustomEditor({
      id: 'keys',
      path: 'keys',
      name: 'Keys',
      description: 'Key to monitor for MQTT updates. To add a custom key name use the format "key;custom name". Ex: "operating_temperature;Temperature"',
      defaultValue: '',
      editor: KeyPicker,
    });

})
